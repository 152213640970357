import React from "react";
import { Helmet } from "react-helmet";

class index extends React.Component {
  // The entire calculator pages use the same template and have the same basic authorization.
  render() {
    return (
      <div id="homepage">
        <Helmet>
          <title>Biolark</title>
          <meta
            name="description"
            content="Page not found"
          />
        </Helmet>
        This page can not be found.
      </div>
    );
  }
}
export default index;